var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHealthTyreStyled', [_c('header', [_c('ComponentHeaderMolecule', {
    attrs: {
      "isCollapsable": true
    },
    on: {
      "expanded": _vm.expanded
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('TalpaIcon', {
          staticClass: "icon",
          attrs: {
            "scope": 'Tyre',
            "name": 'PressureIcon'
          }
        })];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.$tc('tyre')))];
      },
      proxy: true
    }, _vm.hasTyreReadPermission ? {
      key: "components",
      fn: function fn() {
        return [_c('ComponentWrapper', [_c('SeverityMolecule', {
          attrs: {
            "severities": _vm.tyreHealth
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  })], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isExpanded ? _c('main', [_c('AssetHealthTyrePanelOrganism', {
    attrs: {
      "asset": _vm.asset,
      "wheelPositions": _vm.wheelPositions
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }