<template>
  <AssetHealthTyreStyled>
    <header>
      <ComponentHeaderMolecule :isCollapsable="true" @expanded="expanded">
        <template v-slot:icon><TalpaIcon class="icon" :scope="'Tyre'" :name="'PressureIcon'" /></template>
        <template v-slot:title>{{ $tc('tyre') }}</template>
        <template v-slot:components v-if="hasTyreReadPermission">
          <ComponentWrapper>
            <SeverityMolecule :severities="tyreHealth" />
          </ComponentWrapper>
        </template>
      </ComponentHeaderMolecule>
    </header>
    <transition name="fade">
      <main v-if="isExpanded"><AssetHealthTyrePanelOrganism :asset="asset" :wheelPositions="wheelPositions" /></main>
    </transition>
  </AssetHealthTyreStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import permissionsMixin from '@/mixins/permissions'
import ComponentHeaderMolecule from '@/components/Atomic/Molecules/Maintenance/ComponentHeaderMolecule'
import SeverityMolecule from '@/components/Atomic/Molecules/SeverityMolecule'
import AssetHealthTyrePanelOrganism from './AssetHealthTyrePanelOrganism'

import { TalpaIcon } from '@common/components'
import { mappedTyreSeverity, healthMapper } from '@/utils/maintenance/healthUtils'

import ASSET_WITH_WHEELPOSITION_QUERY from '#/graphql/operations/asset/wheelposition/assetWheelPositionsMounted.gql'

const AssetHealthTyreStyled = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-gap: 0.5rem;
`
const ComponentWrapper = styled('div')`
  grid-area: components;
  display: flex;
  flex-direction: row;
  @media (max-width: 820px) {
    flex-direction: column;
    align-items: end;
    gap: 0.5rem;
  }
  @media (max-width: 425px) {
    align-items: start;
  }
`
export default {
  mixins: [permissionsMixin],
  components: {
    AssetHealthTyreStyled,
    ComponentHeaderMolecule,
    SeverityMolecule,
    ComponentWrapper,
    AssetHealthTyrePanelOrganism,
    TalpaIcon,
  },
  data() {
    return {
      isExpanded: true,
      wheelPositions: [],
    }
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasTyreReadPermission() {
      return Boolean(this.tyreReadPermission)
    },
    mappedWheelPositionsData() {
      const filterdWheelPositions = this.wheelPositions ? this.wheelPositions.filter(wheel => wheel.unmountedAt === null) : []
      const wheelPositionsData = filterdWheelPositions.map(wheelPosition => {
        const temperatureServerity = get(wheelPosition, 'temperatureThreshold.severity', '')
        const pressureThresholdSeverity = get(wheelPosition, 'pressureThreshold.severity', '')
        const tyreHealth = mappedTyreSeverity(temperatureServerity, pressureThresholdSeverity)
        return tyreHealth
      })
      return wheelPositionsData
    },
    tyreHealth() {
      const health = healthMapper(this.mappedWheelPositionsData)
      return [health]
    },
    assetID() {
      return get(this.asset, 'id', null)
    },
  },
  methods: {
    expanded(value) {
      this.isExpanded = value
    },
  },
  apollo: {
    wheelPositions: {
      query: ASSET_WITH_WHEELPOSITION_QUERY,
      variables() {
        return {
          id: this.assetID,
        }
      },
      update: data => data?.assetWheelPositions || [],
      skip() {
        return !this.assetID || !this.hasTyreReadPermission
      },
    },
  },
}
</script>
