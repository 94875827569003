<template>
  <NextMaintenanceLabelMoleculeStyled>
    <LabelAtom :label="`${$tc('maintenance.serviceAlerts.nextService')}`" />
    <LabelAtom :label="serviceAlerts && serviceAlerts[0].label" class="text" />
    <LabelAtom :label="serviceAlerts && serviceAlerts[0].timeUntilService" class="text" />
    <LabelAtom :label="serviceDate" class="text" />
    <NextMaintenanceModal :data="serviceAlerts" :asset="asset" />
  </NextMaintenanceLabelMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'
import NextMaintenanceModal from './NextMaintenanceModal.vue'
import localesMixin from '@/mixins/locales'
import { getShortDate } from '@/utils/time'

const NextMaintenanceLabelMoleculeStyled = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
  border-radius: 8px;
  height: 40px;
  width: max-content;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  > .text {
    font-weight: 400;
    font-size: 12px;
  }
`

export default {
  mixins: [localesMixin],
  props: {
    serviceAlerts: {
      type: Array,
      required: true,
    },
    asset: {
      type: Object,
      require: true,
    },
  },
  components: {
    NextMaintenanceLabelMoleculeStyled,
    LabelAtom,
    NextMaintenanceModal,
  },
  computed: {
    serviceDate() {
      return this.serviceAlerts[0]?.serviceDate ? getShortDate(this.serviceAlerts[0]?.serviceDate, this.getCustomLocale()) : '-'
    },
  },
}
</script>
