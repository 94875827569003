<template>
  <InfoWrapperStyled>
    <table>
      <thead>
        <tr>
          <th v-for="column in columns" :key="column.id">
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index">
          <td v-for="cell in row" :key="cell.id">{{ cell.value }}</td>
        </tr>
      </tbody>
    </table>
  </InfoWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const InfoWrapperStyled = styled('div')`
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
  th,
  td {
    padding: 0.5rem;
    text-align: left;
    font-size: 12px;
  }
`
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  components: {
    InfoWrapperStyled,
  },
}
</script>
