var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ComponentHeaderStyled', {
    attrs: {
      "hasDetails": _vm.hasDetails
    }
  }, [_c('header', [_c('div', {
    staticClass: "title"
  }, [_c('IconStyled', [_vm._t("icon")], 2), _c('TextWrapper', [_vm._t("title")], 2)], 1), _vm.hasComponents ? _vm._t("components") : _vm._e(), _vm.isCollapsable ? _c('ExpandToggleArrowWrapper', {
    attrs: {
      "isExpanded": _vm.isExpanded
    },
    on: {
      "click": _vm.toggleExpanding
    }
  }, [_c('ChevronDownIcon', {
    staticClass: "expand-icon"
  })], 1) : _vm._e()], 2), _vm.hasDetails ? _c('DetailsWrapperStyled', [_c('div', {
    staticClass: "details"
  }, [_vm._t("details")], 2)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }