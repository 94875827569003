var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NextMaintenanceLabelMoleculeStyled', [_c('LabelAtom', {
    attrs: {
      "label": "".concat(_vm.$tc('maintenance.serviceAlerts.nextService'))
    }
  }), _c('LabelAtom', {
    staticClass: "text",
    attrs: {
      "label": _vm.serviceAlerts && _vm.serviceAlerts[0].label
    }
  }), _c('LabelAtom', {
    staticClass: "text",
    attrs: {
      "label": _vm.serviceAlerts && _vm.serviceAlerts[0].timeUntilService
    }
  }), _c('LabelAtom', {
    staticClass: "text",
    attrs: {
      "label": _vm.serviceDate
    }
  }), _c('NextMaintenanceModal', {
    attrs: {
      "data": _vm.serviceAlerts,
      "asset": _vm.asset
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }