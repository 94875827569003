<template>
  <ComponentHeaderStyled :hasDetails="hasDetails">
    <header>
      <div class="title">
        <IconStyled><slot name="icon" /></IconStyled>
        <TextWrapper><slot name="title" /></TextWrapper>
      </div>
      <slot name="components" v-if="hasComponents" />
      <ExpandToggleArrowWrapper v-if="isCollapsable" @click="toggleExpanding" :isExpanded="isExpanded">
        <ChevronDownIcon class="expand-icon" />
      </ExpandToggleArrowWrapper>
    </header>
    <DetailsWrapperStyled v-if="hasDetails">
      <div class="details">
        <slot name="details" />
      </div>
    </DetailsWrapperStyled>
  </ComponentHeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronDownIcon } from 'vue-feather-icons'

const ComponentHeaderStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  ${props => (props.hasDetails ? 'grid-template-rows: 0.5fr 0.5fr;' : 'grid-template-rows: 0.5fr;')};
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 8px;
  > header {
    display: grid;
    grid-template-columns: 0.25fr 1fr 50px;
    grid-template-rows: 1fr;
    grid-template-areas: 'title components collapse';
    border-bottom: 2px solid ${({ theme }) => theme.colors.whiteSmokeDark};
    align-items: center;
    padding: 0.5rem 1rem;
    .title {
      display: grid;
      grid-area: title;
      grid-template-columns: 3.5rem 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: 'icon name';
      align-items: center;
    }

    @media (max-width: 425px) {
      grid-template-columns: 1fr 50px;
      grid-template-areas:
        'title collapse'
        'components collapse';
      grid-gap: 0.5rem;
    }
  }
`

const IconStyled = styled('div')`
  grid-area: icon;
  display: flex;
  align-items: center;
  > .icon {
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    svg.icon {
      width: max-content;
      height: max-content;
      padding: 10px;
    }
  }
`

const TextWrapper = styled('div')`
  grid-area: text;
  color: ${({ theme }) => theme.colors.atomic.textSecondary};
  line-height: 32px;
  font-size: 24px;
  font-weight: 400;
  grid-area: name;
  text-transform: capitalize;
`

const ExpandToggleArrowWrapper = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.atomic.primary};
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
  grid-area: collapse;
  & > .expand-icon {
    ${({ isExpanded }) => isExpanded && 'transform: rotate(180deg)'};
  }
  &:hover {
    > .feather {
      color: ${({ theme }) => theme.colors.atomic.primaryActive};
    }
  }
`

const DetailsWrapperStyled = styled('div')`
  display: grid;
  grid-template-columns: 3.5rem 1fr 1fr 50px;
  grid-template-rows: 1fr;
  grid-template-areas: 'icon details details collapse';
  padding: 0.25rem 1rem 0.5rem 1rem;
  .details {
    grid-area: details;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'details';
  }
`

export default {
  components: {
    ComponentHeaderStyled,
    DetailsWrapperStyled,
    ChevronDownIcon,
    IconStyled,
    TextWrapper,
    ExpandToggleArrowWrapper,
  },
  props: {
    isCollapsable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: true,
    }
  },
  computed: {
    hasDetails() {
      return Boolean(this.$slots.details)
    },
    hasComponents() {
      return Boolean(this.$slots.components)
    },
  },
  methods: {
    toggleExpanding() {
      this.isExpanded = !this.isExpanded
      this.$emit('expanded', this.isExpanded)
    },
  },
}
</script>
