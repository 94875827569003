<template>
  <AssetDetailsStyled>
    <div v-for="(item, i) in details" :key="i">
      <KeyValueV2Atom :label="item.key" :value="item.value" />
    </div>
  </AssetDetailsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import KeyValueV2Atom from '@/components/Atomic/Atoms/KeyValueV2Atom.vue'

const AssetDetailsStyled = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: row;
  column-gap: 2rem;
  row-gap: 0.25rem;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 1rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export default {
  components: {
    AssetDetailsStyled,
    KeyValueV2Atom,
  },
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
}
</script>
