<template>
  <ModalStyled>
    <header>
      <slot name="header" />
    </header>
    <main>
      <slot name="main" />
    </main>
    <footer>
      <slot name="footer" />
    </footer>
  </ModalStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { flexStartCenter } from '@styles/mixins'

const ModalStyled = styled('section')`
  width: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: min-content 1fr min-content;
  border-radius: 8px;
  color: ${p => p.theme.colors.navFontNormal};
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  max-height: 80vh;
  overflow-y: auto;
  border: 1px solid
    ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.16).css())};
  > header {
    ${flexStartCenter};
    grid-area: header;
    color: ${p => p.theme.colors.navFontNormal};
    padding: 0.75rem;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 0.75px solid ${props => chroma(props.theme.colors.navFontNormal).alpha(0.2).css()};
  }
  > main {
    grid-area: main;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    overflow: auto;
  }
  > footer {
    padding: 0 0.5rem;
  }
`
export default {
  components: {
    ModalStyled,
  },
}
</script>
