var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHealthOverviewTyreStyled', [!_vm.hasTyreReadPermission && !_vm.isTyreNotificationAcknowledged ? _c('FeatureNotAvailable', {
    staticClass: "content",
    attrs: {
      "title": _vm.tyreNotAvailable.title,
      "message": _vm.tyreNotAvailable.message,
      "imageSrc": _vm.NoTyreFeatureImageSrc
    },
    on: {
      "accepted": _vm.acknowledgeTypeMessage
    }
  }) : _c('TyreStatusTable', {
    staticClass: "content",
    attrs: {
      "route": _vm.$route
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }