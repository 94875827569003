var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InfoWrapperStyled', [_c('table', [_c('thead', [_c('tr', _vm._l(_vm.columns, function (column) {
    return _c('th', {
      key: column.id
    }, [_vm._v(" " + _vm._s(column.label) + " ")]);
  }), 0)]), _c('tbody', _vm._l(_vm.rows, function (row, index) {
    return _c('tr', {
      key: index
    }, _vm._l(row, function (cell) {
      return _c('td', {
        key: cell.id
      }, [_vm._v(_vm._s(cell.value))]);
    }), 0);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }