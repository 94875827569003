var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHealthOverviewStyled', [_vm.$apollo.loading ? _c('TalpaLoaderWrapper') : [_c('AssetHealthEngineOrganism', {
    attrs: {
      "asset": _vm.asset
    }
  }), _c('AssetHealthTyreOrganism', {
    attrs: {
      "asset": _vm.asset
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }