var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDetailsStyled', _vm._l(_vm.details, function (item, i) {
    return _c('div', {
      key: i
    }, [_c('KeyValueV2Atom', {
      attrs: {
        "label": item.key,
        "value": item.value
      }
    })], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }