var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WrapperStyled', {
    directives: [{
      name: "away",
      rawName: "v-away",
      value: _vm.closeModal,
      expression: "closeModal"
    }]
  }, [_c('div', {
    on: {
      "click": _vm.openModal
    }
  }, [_c('InfoIcon', {
    attrs: {
      "size": "1x"
    }
  })], 1), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.modalExpanded ? _c('Modal', {
    staticClass: "modal",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          ref: "title"
        }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.asset.name)))]), _c('XIcon', {
          on: {
            "click": function click($event) {
              return _vm.closeModal('Icon');
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('TableStyled', {
          ref: "serviceDetails"
        }, [_c('div', {
          staticClass: "engineSection sectionDivider"
        }, [_c('span', {
          staticClass: "sectionHeader"
        }, [_vm._v(_vm._s(_vm.$tc('engine', 1)))]), _c('div', {
          staticClass: "details"
        }, [_c('KeyValueV2Atom', {
          attrs: {
            "label": _vm.$tc('model'),
            "value": _vm.engines ? _vm.engines.model.name : '-'
          }
        }), _c('KeyValueV2Atom', {
          attrs: {
            "label": _vm.$tc('serialNumber'),
            "value": _vm.engines ? _vm.engines.serialNumber : '-'
          }
        })], 1)]), _c('div', {
          staticClass: "sectionDivider"
        }, [_c('span', {
          staticClass: "sectionHeader"
        }, [_vm._v(_vm._s(_vm.$t('maintenance.serviceAlerts.nextService')))]), _c('BasicTable', {
          attrs: {
            "columns": _vm.maintenanceColumns,
            "rows": _vm.maintenanceRows
          }
        })], 1), _c('div', {
          staticClass: "sparePartsSection"
        }, [_c('span', {
          staticClass: "sectionHeader"
        }, [_vm._v(_vm._s(_vm.$tc('maintenance.spareParts', 2)))]), !_vm.sparePartsData.length ? _c('div', {
          staticClass: "noData"
        }, [_vm._v(_vm._s(_vm.$t('messages.noSpareParts')))]) : _c('BasicTable', {
          attrs: {
            "columns": _vm.sparePartsColumns,
            "rows": _vm.sparePartsRows
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('ActionsStyled', [_c('ButtonBorderedStyled', {
          on: {
            "click": function click($event) {
              return _vm.closeModal('Button');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.close')) + " ")]), _c('ButtonSolidStyled', {
          on: {
            "click": _vm.copyToClipboard
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.copyInformation')) + " ")])], 1)];
      },
      proxy: true
    }], null, false, 2452129732)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }