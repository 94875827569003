<template>
  <AssetHealthOverviewTyreStyled>
    <FeatureNotAvailable
      v-if="!hasTyreReadPermission && !isTyreNotificationAcknowledged"
      :title="tyreNotAvailable.title"
      :message="tyreNotAvailable.message"
      :imageSrc="NoTyreFeatureImageSrc"
      @accepted="acknowledgeTypeMessage"
      class="content"
    />
    <TyreStatusTable v-else :route="$route" class="content" />
  </AssetHealthOverviewTyreStyled>
</template>

<script>
import Vue from 'vue'
import { styled } from '@egoist/vue-emotion'
import permissionsMixin from '@/mixins/permissions'
import FeatureNotAvailable from '@/components/Atomic/Molecules/FeatureNotAvailable'
import TyreStatusTable from '@/components/Atomic/Organisms/Tyres/TyreStatusTable'

const AssetHealthOverviewTyreStyled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.chartCardBG};
  padding-bottom: 20px;
`

export default {
  inject: ['theme'],
  mixins: [permissionsMixin],
  props: {
    asset: {
      type: Object,
      required: true,
    },
    wheelPositions: {
      type: Array,
      required: true,
    },
  },
  components: {
    AssetHealthOverviewTyreStyled,
    FeatureNotAvailable,
    TyreStatusTable,
  },
  data() {
    return {
      isTyreNotificationAcknowledged: false,
    }
  },
  mounted() {
    this.isTyreNotificationAcknowledged = Vue.localStorage.get('tyreMessageAcknowleged')
  },
  computed: {
    hasTyreReadPermission() {
      return Boolean(this.tyreReadPermission)
    },
    tyreNotAvailable() {
      return {
        title: this.$t('messages.tyreNotAvailableTitle'),
        message: this.$t('messages.tyreNotAvailableMsg'),
      }
    },
    NoTyreFeatureImageSrc() {
      return this.theme.isDark ? '/tyreBannerBlack.png' : '/tyreBannerWhite.png'
    },
  },
  methods: {
    acknowledgeTypeMessage() {
      Vue.localStorage.set('tyreMessageAcknowleged', true)
      this.isTyreNotificationAcknowledged = true
    },
  },
}
</script>
